export const CARABINER = {
  NONE: 0,
  STANDARD: 15,
  CUSTOM: 30
};

export const POPPER = {
    NONE: 0,
    PLAIN: 10,
    WITH_GRAVER: 20,
    INDIVIDUAL: 40
}

export const ROPE = {
    THICKNESS: {
        14: 120,
        12: 115
    },
    LENGTH: {
        350: 0,
        450: 10,
        550: 20,
        650: 30,
        750: 40
    },
}

export const REIN = {
    THICKNESS: {
        14: 90,
        12: 80
    },
    LENGTH: {
        280: 0,
        350: 10
    },
}

export const HALTER = {
    THICKNESS: {
        8: 55,
        6: 50
    },
    CUSTOM_NOSE_BAND: 15
}

export const CORDEO = {
    THICKNESS: {
        14: 60,
        12: 55
    },
    REGULATION: 15
}
