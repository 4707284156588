import {CARABINER} from "../Pricelist";

const PRICELIST = {
    base: 60
}

class UwiazModel {
    constructor() {
        this.productName = "Uwiąz";
        this.colorIndex = 0; // default value, assuming it corresponds to an index in a color array
        this.customCarabiner = false;
        this.price = this.calculatePrice();
    }

    setColorIndex(index) {
        this.colorIndex = index;
    }

    toggleCarabiner() {
        this.customCarabiner = !this.customCarabiner;
        this.calculatePrice();
    }

    calculatePrice() {
        let price = PRICELIST.base

        if (this.customCarabiner) {
            price += CARABINER.CUSTOM;
        }

        this.price = price;
        return price;
    }
}

export default UwiazModel;